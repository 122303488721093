<template>
  <v-card
    class="mt-6 ml-2 mr-2 rounded"
    :style="'background-color: #efe3ff !important;'"
  >
    <v-container class="mt-16 font-size-hero-medium bg-hero">
      <v-row class="mt-4">
        <v-col class="text-center">
          <h1 class="poppins600 text-center text-typo">Jak mogę Ci pomóc ?</h1>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="bg-tranparent mb-4">
      <v-row>
        <v-col
          class="d-flex justify-end"
          cols="10"
          offset="1"
          md="5"
          offset-md="1"
        >
          <v-img src="@/assets/img/about_me.png" contain> </v-img>
        </v-col>
        <v-col cols="10" offset="1" md="5" offset-md-right="1">
          <v-card class="bg-transparent font-size-hero">
            <v-container fluid class="pa-4">
              <v-row class="mt-4">
                <v-text-field
                  hide-details
                  outlined
                  background-color="rgba(255,255,255,.9)"
                  color="rgba(0,0,0,.6)"
                  height="40"
                  placeholder="Email"
                  v-model="email"
                  :disabled="email_readonly"
                  class="
                    input-style
                    font-size-hero
                    border border-radius-md
                    mb-4
                  "
                >
                </v-text-field>
              </v-row>
              <v-row class="mt-4">
                <v-text-field
                  hide-details
                  outlined
                  background-color="rgba(255,255,255,.9)"
                  color="rgba(0,0,0,.6)"
                  height="40"
                  placeholder="Temat"
                  v-model="subject"
                  class="
                    input-style
                    font-size-hero
                    border border-radius-md
                    mb-4
                  "
                >
                </v-text-field>
              </v-row>
              <v-row class="mt-4">
                <v-textarea
                  hide-details
                  outlined
                  background-color="rgba(255,255,255,.9)"
                  color="rgba(0,0,0,.6)"
                  placeholder="Treść"
                  v-model="content"
                  class="
                    input-style
                    font-size-hero
                    border border-radius-md
                    mb-4
                  "
                >
                </v-textarea>
              </v-row>
              <v-row class="mt-4">
                <v-btn
                  elevation="0"
                  :ripple="false"
                  height="43"
                  class="
                    font-weight-bold
                    text-uppercase
                    btn-default
                    bg-gradient-primary
                    py-2
                    px-6
                    me-2
                    mt-6
                    mb-2
                    w-100
                  "
                  :loading="sendLoading"
                  @click="$refs.captcha.execute()"
                  color="#5e72e4"
                  >Wyślij</v-btn
                >
                <vue-recaptcha
                  ref="captcha"
                  :sitekey="$captchaSitekey"
                  @verify="sendMessage"
                  :loadRecaptchaScript="true"
                >
                  <button style="display: none"></button>
                </vue-recaptcha>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import qs from "qs";
import VueRecaptcha from "vue-recaptcha";
export default {
  name: "ContactCard",
  data() {
    return {
      email: "",
      email_readonly: false,
      subject: "",
      content: "",
      sendLoading: false,
    };
  },
  components: {
    VueRecaptcha,
  },
  mounted() {
    this.email = `${this.user && this.user.email ? this.user.email : ""}`;
    this.email_readonly = !!this.email;
  },
  props: {},
  methods: {
    async sendMessage(captcha_token) {
      this.$refs.captcha.reset();
      this.sendLoading = true;
      try {
        await this.$axios({
          method: "post",
          url: "/api/contact/send",
          data: qs.stringify({
            captcha: captcha_token,
            email: this.email,
            subject: this.subject,
            content: this.content
          }),
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        });
        this.$toast.open({
          message: `<span class="font-weight-bold">Wysłano wiadomość !</span>`,
          type: "success",
          duration: 3000,
          position: "bottom",
        });
        this.sendLoading = false;
      } catch (error) {
        this.sendLoading = false;
      }
    },
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
    userHasActivePlan() {
      return this.$store.state.userHasActivePlan;
    },
  },
};
</script>

<style>
</style>