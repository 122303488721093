<template>
  <div>
    <v-container>
      <LogoHeader v-if="$route.meta.layout=='blank'"></LogoHeader>
      <v-row justify="center">
        <v-col cols="12">
          <ContactCard></ContactCard>
        </v-col>
      </v-row>
    </v-container>
    <!-- FOOTER -->
    <v-container class="" v-if="$route.meta.layout=='blank'">
      <Footer></Footer>
    </v-container>
  </div>
</template>

<script>
import LogoHeader from "@/components/util/LogoHeader.vue";
import ContactCard from "@/components/util/ContactCard.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "Contact",
  data() {
    return {
      
    };
  },
  components: {
    LogoHeader,
    Footer,
    ContactCard
  },
};
</script>

<style>
</style>